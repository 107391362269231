import { Link, useNavigate } from 'react-router-dom'
import React from 'react'
import { signOutRemoveKeys } from '../services/token_manager'
import { REACT_APP_BUILD_TIME } from '../config'

const styles = {
  wrapper: {
    display: 'flex',
  },
  item: {
    marginLeft: 16,
  },
}

const Header = () => {
  const token = localStorage.getItem('api.token')
  let navigate = useNavigate()

  function signOut() {
    signOutRemoveKeys()
    navigate('/home')
  }

  if (token) {
    return (
      <div>
        <p> version: {REACT_APP_BUILD_TIME} </p>

        <div style={styles.wrapper}>
          <Link to="/home" className={'text-lg'}>
            Home
          </Link>
          <Link to="/wallet" style={styles.item} className={'text-lg'}>
            Wallet
          </Link>
          <Link to="/robot" style={styles.item} className={'text-lg'}>
            Robot
          </Link>
          <Link to="/stat" style={styles.item} className={'text-lg'}>
            Statistics
          </Link>
          <Link to="#" style={styles.item} className={'text-lg'} onClick={() => signOut()}>
            SignOut
          </Link>
        </div>
      </div>
    )
  } else {
    return <p className={'text-lg'}>Login</p>
  }
}

export default Header
