import React, { useEffect, useReducer } from 'react'
import { useNavigate } from 'react-router-dom'
import { initApp } from '../services/app'
import { login } from '../services/api'

const initialState = {
  username: '',
  password: '',
  isLogging: false,
}

function reduce(state, action) {
  switch (action.type) {
    case 'UsernameChanged': {
      return { ...state, username: action.text }
    }
    case 'PasswordChanged': {
      return { ...state, password: action.text }
    }
    case 'LoginBtnClicked': {
      return { ...state, isLogging: true }
    }
    case 'LoginSucceed': {
      return { ...state, isLogging: false, token: action.token, error: undefined }
    }
    case 'LoginFailed': {
      return { ...state, isLogging: false, error: action.error }
    }
    default:
      throw new Error(`unrecognized ${state} ${action}`)
  }
}

export function LoginPage() {
  const [state, dispatch] = useReducer(reduce, initialState)
  const navigate = useNavigate()
  const { username, password, isLogging, token, error } = state

  useEffect(() => {
    if (isLogging) {
      login(username, password)
        .then((json) => {
          if (json.success) {
            dispatch({ type: 'LoginSucceed', token: json.token })
          } else {
            dispatch({ type: 'LoginFailed', error: json.error })
          }
        })
        .catch((err) => dispatch({ type: 'LoginFailed', error: err.toString() }))
    }
  }, [isLogging])

  useEffect(() => {
    if (token) {
      initApp(token).then(console.log).catch(console.error)
    }
  }, [token])

  useEffect(() => {
    if (token) {
      // give sometime for localstorage setting
      setTimeout(() => navigate('/home'), 1000)
    }
  }, [token])

  return (
    <div className={'mx-auto w-[400px]'}>
      <div style={{ color: 'red' }}>{error}</div>
      <div className="form-control w-full">
        <label className="label">
          <span className="label-text text-lg">username</span>
        </label>
        <input
          type="text"
          onChange={(e) => dispatch({ type: 'UsernameChanged', text: e.target.value })}
          className="input input-bordered w-full"
        />
      </div>
      <div className="form-control w-full">
        <label className="label">
          <span className="label-text text-lg">password</span>
        </label>
        <input
          type="password"
          onChange={(e) => dispatch({ type: 'PasswordChanged', text: e.target.value })}
          className="input input-bordered w-full"
        />
      </div>
      <button
        className="btn w-full mt-4"
        disabled={isLogging === true}
        onClick={() => dispatch({ type: 'LoginBtnClicked' })}
      >
        Login
      </button>
    </div>
  )
}
