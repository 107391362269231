import axios from 'axios'

/**
 * https://zcash-rpc.github.io/
 */
export class ZCashRpc {
  static get default() {
    if (!this.defaultInstance) {
      this.defaultInstance = new ZCashRpc({
        url: process.env.ZCASH_RPC_URL || 'https://mzc.whalefx.com/',
        username: process.env.ZCASH_RPC_USER || 'admin',
        password: process.env.ZCASH_RPC_PASS || 'ymiao123#$',
        timeout: 600000,
      })
    }
    return this.defaultInstance
  }

  static defaultInstance

  client
  idPrefix = this.getRandomId(5)
  id = 0

  constructor(arg) {
    this.client = axios.create({
      baseURL: arg.url,
      auth: { username: arg.username, password: arg.password },
      timeout: arg.timeout,
    })
    this.client.interceptors.response.use((resp) => {
      if (resp.status !== 200) throw resp
      if (resp.data.error) throw resp.data.error
      return resp
    })
  }

  getHeight() {
    return this.getBlockCount()
  }

  async getBalance(address) {
    const result = await this.getAddressBalance([address])
    return result.balance
  }

  // -------------------------------------------- Addressindex --------------------------------------------

  async getAddressUtxos(addresses, chainInfo = false) {
    const res = await this.request('getaddressutxos', [{ addresses, chainInfo }])
    return res
  }

  // -------------------------------------------- Blockchain --------------------------------------------

  async getBlockchainInfo() {
    return this.request('getblockchaininfo')
  }

  async getBlockCount() {
    return this.request('getblockcount')
  }

  async getAddressBalance(addresses) {
    return this.request('getaddressbalance', [{ addresses }])
  }

  // -------------------------------------------- misc --------------------------------------------

  async request(method, params = []) {
    const id = `${this.idPrefix}${this.id++}`
    const resp = await this.client.post('', {
      jsonrpc: '2.0',
      id,
      method,
      params,
    })
    const data = resp.data
    return data.result
  }

  getRandomId(length) {
    let result = ''
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    const charactersLength = characters.length
    let counter = 0
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
      counter += 1
    }
    return result
  }
}

export const zcashRpc = ZCashRpc.default
