import TrezorConnect from '@trezor/connect-web'
import { BTC_NETWORK } from '../config'

export async function getDepositXPubKey(depositXPubPath, callback) {
  var path = depositXPubPath // first BIP44 account

  const response = await TrezorConnect.getPublicKey({ path })
  console.log('response ', response)
  if (response.success) {
    callback(null, response.payload.xpub)
  } else {
    callback(null, response.payload.error)
  }
}

export async function getCollectingAddress(collectAddrPath, callback) {
  const response = await TrezorConnect.ethereumGetAddress({ path: collectAddrPath })
  console.log('response ', response)
  if (response.success) {
    callback(null, response.payload.address)
  } else {
    callback(null, response.payload.error)
  }
}

export async function getLegacyAddress(path, callback) {
  let coin = BTC_NETWORK === 'testnet' ? 'testnet' : 'Bitcoin'
  // var coin = 'Bitcoin' // "Bitcoin", "Litecoin",  etc testnet
  const response = await TrezorConnect.getAddress({ path, coin })
  console.log('response', response)
  if (response.success) {
    callback(null, response.payload.address)
  } else {
    callback(null, response.payload.error)
  }
}

export async function getAddress(path, coin) {
  const result = await TrezorConnect.getAddress({ path, coin })
  return result.payload.address
}

export { fetchXpub } from './api'
