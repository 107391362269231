import * as bitcoinjs from 'bitcoinjs-lib'

function rotateAddressM2L(address) {
  const result = bitcoinjs.address.fromBase58Check(address)
  let version = result.version
  switch (version) {
    case 5:
      // Mainnet p2sh address
      version = 50
      break
    case 50:
      // Mainnet p2sh address (deprecated)
      version = 5
      break
    case 196:
      // Testnet p2sh address
      version = 58
      break
    case 58:
      // Testnet p2sh address (deprecated)
      version = 196
      break
    default:
      throw new Error('Invalid address version')
  }
  return bitcoinjs.address.toBase58Check(result.hash, version)
}

export default {
  rotateAddressM2L,
}
