import Filecoin, { errors as walletProviderErrors, LedgerProvider } from '@glif/filecoin-wallet-provider'
import HTTPTransport from '@ledgerhq/hw-transport-http'
import { Message } from '@glif/filecoin-message'
import { FilecoinNumber } from '@glif/filecoin-number'
import { getTransport } from './index'

export const LEDGER_VERSION_MAJOR = 0
export const LEDGER_VERSION_MINOR = 18
export const LEDGER_VERSION_PATCH = 2

export const getProvider = async () => {
  const provider = new LedgerProvider({
    transport: await getTransport(),
    minLedgerVersion: { major: LEDGER_VERSION_MAJOR, minor: LEDGER_VERSION_MINOR, patch: LEDGER_VERSION_PATCH },
  })
  try {
    await provider.ready()
  } catch (err) {
    const desc = '尝试重新插拔ledger、解锁、打开filecoin app'
    if (err instanceof Error) {
      if (err instanceof walletProviderErrors.LedgerFilecoinAppNotOpenError) {
        throw new Error(`LEDGER_FILECOIN_APP_NOT_OPEN ${desc}`)
      } else if (err instanceof walletProviderErrors.LedgerDeviceBusyError) {
        throw new Error(`LEDGER_BUSY ${desc}`)
      } else if (err instanceof walletProviderErrors.LedgerNotFoundError) {
        throw new Error(`LEDGER_NOT_FOUND ${desc}`)
      } else if (err instanceof walletProviderErrors.LedgerLostConnectionError) {
        throw new Error(`LEDGER_LOCKED ${desc}`)
      } else if (err instanceof walletProviderErrors.TransportNotSupportedError) {
        throw new Error(`TRANSPORT_UNSUPPORTED ${desc}`)
      } else if (err instanceof walletProviderErrors.LedgerReplugError) {
        throw new Error(`LEDGER_REPLUG ${desc}`)
      } else if (err instanceof walletProviderErrors.LedgerDisconnectedError) {
        throw new Error(`LEDGER_LOCKED ${desc}`)
      } else if (err instanceof walletProviderErrors.LedgerInUseByAnotherApp) {
        throw new Error(`LEDGER_USED_BY_ANOTHER_APP ${desc}`)
      } else if (err instanceof walletProviderErrors.LedgerDeviceLockedError) {
        throw new Error(`LEDGER_LOCKED ${desc}`)
      } else if (err instanceof walletProviderErrors.LedgerFilecoinAppBadVersionError) {
        throw new Error(`LEDGER_BAD_VERSION ${desc}`)
      } else {
        throw new Error(`LEDGER_REPLUG ${desc}`)
      }
    } else {
      console.log('UNHANDLED', err.message)
      throw new Error(`LEDGER_REPLUG ${desc}`)
    }
  }
  return provider
}

export const getApp = (provider) => {
  return new Filecoin(provider, {
    apiAddress: 'https://api.node.glif.io',
  })
}

export const getAddress = (provider) => async (path) => {
  const res = await provider.showAddressAndPubKey(path)
  return res.addrString
}

export const getBalance = async (address) => {
  const transport = HTTPTransport('https://api.node.glif.io')
  const app = new Filecoin(transport, { apiAddress: 'https://api.node.glif.io' })
  const balance = await app.getBalance(address)
  return balance.toNumber()
}

export const send = (app) => async (from, to, _value) => {
  console.log('filecoin send', from, to, _value)
  const nonce = await app.getNonce(from)
  console.log('send getNonce %d', nonce)
  const value = new FilecoinNumber(_value, 'fil').toAttoFil()
  const res = await app.gasEstimateMaxFee(
    new Message({
      to,
      from,
      value,
      nonce: 0,
      method: 0,
      params: '',
      gasFeeCap: 0,
      gasLimit: 0,
      gasPremium: 0,
    }).toLotusType(),
  )
  console.log('send gasEstimateMaxFee %O', res)
  const message = new Message({
    to,
    from,
    value,
    method: 0,
    gasPremium: res.message.GasPremium,
    gasFeeCap: res.message.GasFeeCap,
    gasLimit: res.message.GasLimit,
    nonce,
    params: '',
  })
  console.log('send message %O', message)
  const signedMessage = await app.wallet.sign(from, message.toLotusType())
  console.log('send signedMessage %O', signedMessage)
  if (await app.simulateMessage(message.toLotusType())) {
    const cid = (await app.sendMessage(signedMessage))['/']
    console.log('send cid %s', cid)
    return message.toPendingMessage(cid)
  } else {
    throw new Error('Filecoin message invalid. No gas or fees were spent.')
  }
}
