import React, { useEffect, useState } from 'react'
import { fetchStat } from '../services/api'
import BigNumber from 'bignumber.js'
import { format } from '../utils'

const Row = ({
  coinType,
  fee,
  usedFee,
  withdraw,
  deposit,
  commission,
  depositAmount,
  withdrawAmount,
  commissionAmount,
}) => {
  return (
    <tr key={coinType}>
      <td>{coinType}</td>
      <td>{format(deposit || 0)}</td>
      <td>{format(withdraw || 0)}</td>
      <td>{format(new BigNumber(deposit || 0).minus(withdraw || 0).toNumber())}</td>
      <td>{format(fee || 0)}</td>
      <td>{format(usedFee || 0)}</td>
      <td>{format(depositAmount)}</td>
      <td>{format(withdrawAmount)}</td>
      <td>{format(commissionAmount)}</td>
      <td>{format(commission)}</td>
    </tr>
  )
}

function Stat() {
  const [stat, setStat] = useState([])

  useEffect(() => {
    var i = setInterval(async () => {
      const stat = await fetchStat()
      setStat(stat)
    }, 5000)
    return () => {
      clearInterval(i)
    }
  }, [])

  return (
    <div>
      <h1>Statistics</h1>
      <table className={'table table-zebra table-compact w-full'}>
        <thead>
          <tr>
            <td>coinType</td>
            <td>deposit</td>
            <td>withdraw</td>
            <td>balance</td>
            <td>fee</td>
            <td>usedFee</td>
            <td>depositAmount</td>
            <td>withdrawAmount</td>
            <td>commissionAmount</td>
            <td>commission</td>
          </tr>
        </thead>
        <tbody>{Object.keys(stat).map((coinType) => Row({ coinType, ...stat[coinType] }))}</tbody>
      </table>
    </div>
  )
}

export default Stat
