import Web3 from 'web3'
import BigNumber from 'bignumber.js'
import {
  APP_ENV,
  BTC_MAIN_WALLET_FULL_PATH,
  ETH_MAIN_WALLET_FULL_PATH,
  ETH_MAIN_WALLET_KEY,
  USDT_MAIN_WALLET_KEY,
  USDT_MIN_AMOUNT,
  ZEC_MAIN_WALLET_KEY,
} from '../config'
import abiArr from '../static/erc20.abi'
import { getChainByCoinType, getTokens } from './token_manager'
import {
  fetchGasPriceWei,
  fetchUsdtBalance,
  fetchUtxo,
  fetchUtxoTotal,
  fetchWithdrawNonce,
  fetchZECUnspendUXTOs,
  getETHChainId,
  getETHProvider,
  modifyTxSend,
} from './api'
import { convertUtxo2Input, estimateTxSizeByByte, paddingAmount, path2AddressN } from '../utils'
import Trezor7 from './trezor7'
import TrezorConnect from '@trezor/connect-web'
import Tron from './tron'
import * as filecoinUtil from '../utils/wallet/hd/ledger/filecoin'
import { FilecoinNumber } from '@glif/filecoin-number'

let tokenInstances = null

export const getContract = (coinType) => {
  if (tokenInstances === null) {
    tokenInstances = Object.values(getTokens()).reduce((acc, item, index) => {
      const { contractHash, dbSymbol, chain } = item
      const Contract = getETHProvider({ chain }).eth.Contract
      const newContract = new Contract(abiArr, contractHash)
      return {
        ...acc,
        [dbSymbol]: newContract,
      }
    }, {})
  }
  return tokenInstances[coinType]
}

export function signAndSendTx(opts, callback) {
  const { coinType } = opts
  switch (coinType) {
    case 'ETH':
    case 'ETC':
      signEthTxSend(opts, callback)
      break
    case 'USDT':
      signUsdtTxSend(opts, callback)
      break
    case 'BTC':
      signBtcTxSend(opts, callback)
      break
    case 'USDTTRC20':
    case 'USDCTRC20':
    case 'TRX':
      Tron.signTx(opts, callback)
      break
    case 'FIL': {
      ;(async () => {
        try {
          const res = await signFILTx(opts.to, opts.value, opts.collectPath)
          callback(null, { txid: res.hash, nonce: res.nonce, fee: res.fee })
        } catch (e) {
          callback(e)
        }
      })()
      break
    }
    case 'ZEC': {
      ;(async () => {
        try {
          // 转出地址
          const from = opts.collectPath ? opts.collectAddr : localStorage.getItem(ZEC_MAIN_WALLET_KEY)
          const res = await signZECTx(from, opts.to, opts.value, opts.collectPath, opts)
          callback(null, { txid: res.payload.txid, fee: new BigNumber('0.00001').toNumber() })
        } catch (e) {
          callback(e)
        }
      })()
      break
    }
    default:
      signTokenTxSend(opts, callback)
  }
}

async function signFILTx(to, value, collect) {
  // 有path是收集，没有是提币
  const start = parseInt(collect?.match(/.*\/(\d+)/)[1] ?? '0')
  const provider = await filecoinUtil.getProvider()
  const app = await filecoinUtil.getApp(provider)
  const account = (await app.wallet.getAccounts(start, start + 1, 'f'))[0]
  const res = await filecoinUtil.send(app)(account, to, value)
  return {
    hash: res.cid,
    nonce: parseInt(res.nonce),
    fee: new FilecoinNumber(res.gasFeeCap, 'attofil').times(parseInt(res.gasLimit)).toString(),
  }
}

// 有collect是收集
// https://github.com/trezor/trezor-suite/blob/develop/packages/connect/e2e/__fixtures__/signTransactionZcash.ts
async function signZECTx(from, to, value, collect, txsend) {
  // 每input/output占用byte数量
  const kB_PER_INPUT = 150
  const kB_PER_OUTPUT = 80

  // 手续费率
  const feeRate = prompt('填写手续费率（默认 0.00000001 ZEC/kB）', '0.00000001')
  console.log(`ZEC手续费率 ${feeRate}/kB`)

  // 获取转出地址的utxo
  // [
  //   {
  //     address: 't1fjcDxYQLh1Q9VrRwu9BMchJb619HGpvNs',
  //     txid: '6139218e1897965e3df3dff19c0b22f1950fc694a02ab341b57a4ea24856e08b',
  //     outputIndex: 0,
  //     script: '76a914efcf9f08614d484617117a965721474fb8f00b5f88ac',
  //     satoshis: 1000010000,
  //     height: 59461,
  //   }
  // ]
  const utxos = await fetchZECUnspendUXTOs(from)

  // 构造输入参数
  const walletIdx = parseInt(collect?.match(/.*\/(\d+)/)[1] ?? '0')
  const inputAddressN = [(44 | 0x80000000) >>> 0, (133 | 0x80000000) >>> 0, (0 | 0x80000000) >>> 0, 0, walletIdx]
  let withdrawAmount = new BigNumber(value).times(1e8).integerValue(BigNumber.ROUND_DOWN).div(1e8)
  console.log(`ZEC 转出金额 ${withdrawAmount.toString()} ZEC`)

  let accumulateValue = new BigNumber(0)
  const inputs = []
  utxos.forEach((utxo) => {
    if (accumulateValue.gte(withdrawAmount)) {
      return
    }
    accumulateValue = accumulateValue.plus(utxo.satoshis / 1e8)
    inputs.push({
      address_n: inputAddressN,
      prev_hash: utxo.txid,
      prev_index: utxo.outputIndex,
      amount: `${utxo.satoshis}`,
    })
  })
  console.log(`ZEC 累计输入 ${accumulateValue.toString()}`)
  if (accumulateValue.lt(withdrawAmount)) {
    throw new Error('余额不足')
  }

  const fee = new BigNumber(feeRate).times(kB_PER_INPUT * inputs.length + kB_PER_OUTPUT * 2)
  const confirm = window.confirm(`手续费${fee}ZEC，是否提交?`)
  if (!confirm) return

  let withdrawWithFee = withdrawAmount.plus(fee)
  if (accumulateValue.lt(withdrawWithFee)) {
    withdrawAmount = accumulateValue.minus(fee)
    withdrawWithFee = accumulateValue
    console.log(`ZEC 转出金额修正（扣除手续费） ${withdrawAmount.toString()} ZEC`)
  }
  // 构造输出参数
  const outputs = []
  if (withdrawAmount.gt(0)) {
    if (collect) {
      withdrawAmount = accumulateValue.minus(fee)
      console.log(`ZEC 收集金额修正（扣除手续费） ${withdrawAmount.toString()} ZEC`)
      await modifyTxSend(txsend.id, { amount: withdrawAmount.toString() })
    }
    outputs.push({
      address: to,
      amount: withdrawAmount.times(1e8).toString(),
      script_type: 'PAYTOADDRESS',
    })
  }

  // 收集情况下不能找零，要全部转到收集地址上
  if (!collect) {
    // 找零
    const repay = accumulateValue.minus(withdrawWithFee)
    if (repay.gt(0)) {
      outputs.push({
        address: from,
        amount: repay.times(1e8).toString(),
        script_type: 'PAYTOADDRESS',
      })
      console.log(`ZEC 找零： ${repay.toString()} ZEC`)
    }
  }

  if (outputs.length === 0) throw new Error('转出金额为0')
  // 签名数据
  const params = {
    coin: 'zec',
    push: true,
    inputs,
    outputs,
  }
  console.log('ZEC 未签名数据 ', JSON.stringify(params))
  const signResp = await TrezorConnect.signTransaction(params)
  console.log('ZEC 签名数据 ', JSON.stringify(signResp))
  if (!signResp.success) {
    throw Error(JSON.stringify(signResp.payload))
  }
  return signResp
}

const estimateTokenGas = async (opts, sendingAddr) => {
  const { to, coinType, value } = opts
  const { decimals, contractHash } = getTokens()[coinType]
  const contract = getContract(coinType)

  contract.options.from = sendingAddr

  const transferBN = new BigNumber(value).multipliedBy(new BigNumber(10).exponentiatedBy(new BigNumber(decimals)))
  const encodeABI = contract.methods.transfer(to, transferBN.toFixed()).encodeABI()

  const rawTx = {
    from: sendingAddr,
    to: contractHash,
    data: encodeABI,
    // chainId: getChainId({ coinType })
  }
  const estimateGas = await getETHProvider({ coinType }).eth.estimateGas(rawTx)
  console.log('estimateGas:', estimateGas)

  return {
    encodeABI,
    gasLimit: estimateGas,
  }
}

async function signTokenTxSend(opts, callback) {
  console.log('signTokenTxSend opts: ', opts)
  const { collectPath, collectAddr, coinType } = opts

  const walletCollectingAddr = localStorage.getItem(ETH_MAIN_WALLET_KEY)

  if (!walletCollectingAddr) {
    callback('call get sending addr first, sending addr is null')
    return
  }
  var sendingAddrPath = ETH_MAIN_WALLET_FULL_PATH
  var sendingAddr = walletCollectingAddr

  if (collectPath != null) {
    sendingAddr = collectAddr
    sendingAddrPath = collectPath
  }

  const infura = getETHProvider({ coinType })
  const chainNonce = await infura.eth.getTransactionCount(sendingAddr, 'latest')
  const dbNonce = await fetchWithdrawNonce(`trezor.${getChainByCoinType(coinType)}`)
  let nonce = collectPath ? chainNonce : Math.max(chainNonce, dbNonce + 1)
  nonce = prompt('确认 nonce?', nonce)
  if (nonce === null) {
    console.log('warn retun due to nonce', nonce)
    return
  }
  console.log('mNonce is ', nonce)

  console.log('dbNonce is %d, chainNonce is %d, nonce is %s', dbNonce + 1, chainNonce, nonce)

  const priceInWei = await fetchGasPriceWei(coinType)
  let { encodeABI, gasLimit } = await estimateTokenGas(opts, sendingAddr)

  const fee = new BigNumber(priceInWei).multipliedBy(new BigNumber(gasLimit)).dividedBy(new BigNumber(1e18))

  const hexNonce = Web3.utils.toHex(nonce)
  const hexGasPrice = Web3.utils.toHex(priceInWei)
  const hexGasLimit = Web3.utils.toHex(gasLimit)

  const chainId = getETHChainId({ coinType })

  const transaction = {
    to: getTokens()[coinType].contractHash,
    value: '',
    data: encodeABI,
    chainId,
    nonce: hexNonce,
    gasLimit: hexGasLimit,
    gasPrice: hexGasPrice,
  }

  try {
    const txid = await Trezor7.sendETHTxsend(
      {
        path: sendingAddrPath,
        transaction,
      },
      infura,
    )
    callback(null, {
      txid,
      nonce: Web3.utils.hexToNumber(hexNonce),
      fee: fee.toNumber(),
    })
  } catch (error) {
    callback(error)
  }
}

async function signEthTxSend(opts, callback) {
  const { collectPath, collectAddr, to, value, coinType } = opts
  console.log('sign %s TxSend opts: ', coinType, opts)

  const walletCollectingAddr = localStorage.getItem(ETH_MAIN_WALLET_KEY)

  if (!walletCollectingAddr) {
    callback('call get sending addr first, sending addr is null')
    return
  }

  var sendingAddrPath = ETH_MAIN_WALLET_FULL_PATH
  var sendingAddr = walletCollectingAddr

  if (collectPath != null) {
    sendingAddr = collectAddr
    sendingAddrPath = collectPath
  }
  const infura = getETHProvider({ coinType })
  const chainNonce = await infura.eth.getTransactionCount(sendingAddr, 'pending')
  const dbNonce = await fetchWithdrawNonce(getChainByCoinType(coinType))

  let nonce = collectPath ? chainNonce : Math.max(chainNonce, dbNonce + 1)
  nonce = prompt('确认 nonce?', nonce)
  if (nonce === null) {
    console.log('warn retun due to nonce', nonce)
    return
  }
  console.log('mNonce is ', nonce)

  console.log('dbNonce is %d, chainNonce is %d, nonce is %s', dbNonce + 1, chainNonce, nonce)

  let priceInWei = await fetchGasPriceWei(coinType)

  priceInWei = prompt('确认 priceInWei?', priceInWei)
  if (priceInWei === null) {
    console.log('warn return due to priceInWei', priceInWei)
    return
  }
  console.log('mPriceInWei is ', priceInWei)

  const valueInWei = Web3.utils.toWei(value.toString())
  console.log('priceInWei %s is', coinType, priceInWei)

  let gasLimit

  gasLimit = await infura.eth.estimateGas({
    from: sendingAddr,
    to: to,
    value: valueInWei,
  })
  console.warn('new gasLimit is ', gasLimit)
  const fee = new BigNumber(priceInWei).multipliedBy(new BigNumber(gasLimit)).dividedBy(new BigNumber(1e18))

  const hexNonce = Web3.utils.toHex(nonce)
  const hexGasPrice = Web3.utils.toHex(priceInWei)
  const hexGasLimit = Web3.utils.toHex(gasLimit)
  const hexValue = Web3.utils.toHex(valueInWei)

  console.log('fee is', fee.toNumber())

  const chainId = getETHChainId({ coinType })

  const transaction = {
    to: to,
    value: hexValue,
    data: '',
    chainId,
    nonce: hexNonce,
    gasLimit: hexGasLimit,
    gasPrice: hexGasPrice,
  }

  try {
    const txid = await Trezor7.sendETHTxsend(
      {
        path: sendingAddrPath,
        transaction,
      },
      infura,
    )
    callback(null, {
      txid,
      nonce: Web3.utils.hexToNumber(hexNonce),
      fee: fee.toNumber(),
    })
  } catch (error) {
    callback(error)
  }
}

const OP_RETURN_PREFIX = '6f6d6e69000000000000001f'

async function signUsdtTxSend(opts, callback) {
  console.log('sign usdt TxSend opts: ', opts)
  const { collectPath, collectAddr, to, value } = opts

  const walletCollectingAddr = localStorage.getItem(USDT_MAIN_WALLET_KEY)

  if (!walletCollectingAddr) {
    callback('call get sending addr first, sending addr is null')
    return
  }
  const opReturn = paddingAmount(new BigNumber(value).multipliedBy(1e8).toString(16), 16)
  console.log('opReturn is', opReturn)

  var sendingAddrPath = BTC_MAIN_WALLET_FULL_PATH
  var sendingAddr = walletCollectingAddr

  if (collectPath) {
    sendingAddrPath = collectPath
    sendingAddr = collectAddr
  }
  let balance = -1
  try {
    const balanceInBtcStr = await fetchUsdtBalance(sendingAddr)
    console.log('blance in btc str %o', balanceInBtcStr)
    balance = new BigNumber(balanceInBtcStr || 0).div(1e8).toNumber()
    console.log('signUsdtTxSend %s balance %f, tx value ', sendingAddr, balance, value)
  } catch (error) {
    callback('error when fetch usdt balance %o', error)
    return
  }

  if (balance < value) {
    callback('sender has insuffcient balance')
    return
  }

  const utxoArr = await fetchUtxo(sendingAddr)
  const sumInSatoshi = await fetchUtxoTotal(sendingAddr)
  const inputs = convertUtxo2Input(utxoArr, path2AddressN(sendingAddrPath))

  // const { 25: priceInBtc } = await extimateGas(25)
  const priceInBtc = 0.00004
  const bytes = estimateTxSizeByByte(inputs.length, collectPath ? 2 : 3)
  const feeBN = new BigNumber(bytes)
    .dividedBy(new BigNumber(1e3))
    .multipliedBy(new BigNumber(priceInBtc))
    .decimalPlaces(8)

  const amountBN = collectPath
    ? new BigNumber(sumInSatoshi).dividedBy(new BigNumber(1e8)).minus(feeBN).multipliedBy(new BigNumber(1e8))
    : new BigNumber(USDT_MIN_AMOUNT).multipliedBy(new BigNumber(1e8))

  console.log('priceInBtc is', priceInBtc)
  console.log('feeBN is', feeBN.toNumber())
  console.log('amountBN is', amountBN.toNumber())
  console.log('sumInSatoshi is', sumInSatoshi)

  if (amountBN.toNumber() < USDT_MIN_AMOUNT * 1e8) {
    callback('btc amount is too low')
    return
  }

  var outputs = [
    {
      address: to,
      amount: amountBN.toString(),
      script_type: 'PAYTOADDRESS',
    },
    {
      amount: '0',
      script_type: 'PAYTOOPRETURN',
      op_return_data: OP_RETURN_PREFIX + opReturn, // in hexadecimal
    },
  ]

  // if (widhdraw)
  if (!collectPath) {
    const changeBN = new BigNumber(sumInSatoshi).minus(amountBN).minus(feeBN.multipliedBy(new BigNumber(1e8)))

    outputs.push({
      address: sendingAddr,
      amount: changeBN.toString(),
      script_type: 'PAYTOADDRESS',
    })
  }

  try {
    const trezorBtcTx = {
      inputs,
      outputs,
      coin: APP_ENV === 'prod' ? 'BTC' : 'TEST',
    }
    const txid = await Trezor7.sendBTCTxsend(trezorBtcTx)
    callback(null, {
      txid,
      fee: feeBN.toNumber(),
    })
  } catch (error) {
    callback(error)
  }
}

async function signBtcTxSend(opts, callback) {
  console.log('sign btc withdraw opts: ', opts)
  const { to, value } = opts

  const walletCollectingAddr = localStorage.getItem(USDT_MAIN_WALLET_KEY)

  if (!walletCollectingAddr) {
    callback('call get sending addr first, sending addr is null')
    return
  }

  var sendingAddrPath = BTC_MAIN_WALLET_FULL_PATH
  var sendingAddr = walletCollectingAddr

  const amountBN = new BigNumber(value).multipliedBy(new BigNumber(1e8))

  const utxoArr = await fetchUtxo(sendingAddr)
  const sumAmount = await fetchUtxoTotal(sendingAddr)
  const inputs = convertUtxo2Input(utxoArr, path2AddressN(sendingAddrPath))
  const bytes = estimateTxSizeByByte(inputs.length, 2)
  // const { 25: priceInBtc } = await extimateGas(25)
  const priceInBtc = 0.00004

  const feeBN = new BigNumber(bytes)
    .dividedBy(new BigNumber(1e3))
    .multipliedBy(new BigNumber(priceInBtc))
    .decimalPlaces(8)
  console.log('feeBN is', feeBN.toNumber())

  const changeBN = new BigNumber(sumAmount).minus(amountBN).minus(feeBN.multipliedBy(new BigNumber(1e8)))

  var outputs = [
    {
      address: to,
      amount: amountBN.toString(),
      script_type: 'PAYTOADDRESS',
    },
    {
      address: sendingAddr,
      amount: changeBN.toString(),
      script_type: 'PAYTOADDRESS',
    },
  ]
  try {
    const trezorBtcTx = {
      inputs,
      outputs,
      coin: APP_ENV === 'prod' ? 'BTC' : 'TEST',
    }
    const txid = await Trezor7.sendBTCTxsend(trezorBtcTx)
    callback(null, {
      txid,
      fee: feeBN.toNumber(),
    })
  } catch (error) {
    callback(error)
  }
}
