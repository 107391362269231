import React, { Component } from 'react'
import { getCollectingAddress, getDepositXPubKey } from '../services/address'
import { setWallet } from '../services/api'
import {
  ETH_USER_WALLET_BASE_PATH,
  ETH_NETWORK,
  ETH_MAIN_WALLET_FULL_PATH,
  ETH_MAIN_WALLET_KEY,
  ETH_XPUB_KEY,
} from '../config'

export default class EthWallet extends Component {
  constructor(props) {
    super(props)
    this.state = {
      walletId: null,
      sendingAddr: localStorage.getItem(ETH_MAIN_WALLET_KEY),
      xpub: localStorage.getItem(ETH_XPUB_KEY),
      show: false,
    }
  }

  onClickGetSendingAddress() {
    getCollectingAddress(ETH_MAIN_WALLET_FULL_PATH, (err, r) => {
      if (r) {
        const sendingAddr = r.toLowerCase()
        this.setState({ sendingAddr })
        localStorage.setItem(ETH_MAIN_WALLET_KEY, sendingAddr)
      }
    })
  }

  onClickGetXpub() {
    getDepositXPubKey(ETH_USER_WALLET_BASE_PATH, (e, r) => {
      if (r) {
        this.setState({ xpub: r })
        localStorage.setItem(ETH_XPUB_KEY, r)
      }
    })
  }

  onClickClearStorage() {
    localStorage.removeItem(ETH_MAIN_WALLET_KEY)
    localStorage.removeItem(ETH_XPUB_KEY)
    window.location.reload()
  }

  onClickSaveWallet() {
    const testnet = ETH_NETWORK !== 'mainnet'
    setWallet({
      coin: 'ETH',
      coinType: 'ETH',
      xpub: localStorage.getItem(ETH_XPUB_KEY),
      xpubPath: ETH_USER_WALLET_BASE_PATH,
      sendingAddr: localStorage.getItem(ETH_MAIN_WALLET_KEY),
      testnet,
    }).then((r) => this.setState({ walletId: r.id }))
  }

  _toggle = () => {
    this.setState({
      show: !this.state.show,
    })
  }

  componentDidMount() {}

  render() {
    if (!this.state.show) {
      return <h2 onClick={this._toggle}>ETH Wallet (click to show)</h2>
    }
    return (
      <div>
        <h2 onClick={this._toggle}>ETH Wallet (click to hide)</h2>
        <div>
          <p>network: {ETH_NETWORK}</p>
          <p>wallet id: {this.state.walletId || 'wallet not saved'}</p>
          <button onClick={() => this.onClickClearStorage()} className={'btn btn-sm'}>
            clear storage cache
          </button>
          <button onClick={() => this.onClickSaveWallet()} className={'btn btn-sm'}>
            save wallet
          </button>
        </div>
        <div>
          <p>wallet xpub: {this.state.xpub}</p>
          <button onClick={() => this.onClickGetXpub()} className={'btn btn-sm'}>
            get xpub key
          </button>
        </div>
        <div>
          <p>wallet sending addr: {this.state.sendingAddr}</p>
          <button onClick={() => this.onClickGetSendingAddress()} className={'btn btn-sm'}>
            get sending addr
          </button>
        </div>
      </div>
    )
  }
}
