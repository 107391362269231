import { Transaction } from 'ethereumjs-tx'

import { formatHex } from '../utils'

import TrezorConnect from '@trezor/connect-web'
import { sendBtcTx } from './api'
import Common from 'ethereumjs-common'

TrezorConnect.init({
  lazyLoad: true,
  manifest: {
    appUrl: 'http://your.application.com',
    email: '1060996790@qq.com',
  },
})

export default class Trezor7 {
  static async sendETHTxsend(trezorEthTx, infura) {
    console.log('trezorEthTx :', trezorEthTx)

    const result = await TrezorConnect.ethereumSignTransaction(trezorEthTx)
    const {
      success,
      payload: { r, s, v, error },
    } = result
    if (!success) {
      throw new Error('ethereumSignTransaction error ' + error)
    }

    const {
      transaction: { nonce, gasPrice, gasLimit, to, value, data, chainId },
    } = trezorEthTx
    const raw = {
      nonce: Buffer.from(formatHex(nonce), 'hex'),
      gasPrice: Buffer.from(formatHex(gasPrice), 'hex'),
      gasLimit: Buffer.from(formatHex(gasLimit), 'hex'),
      to: Buffer.from(formatHex(to), 'hex'),
      value: Buffer.from(formatHex(value), 'hex'),
      data: Buffer.from(formatHex(data), 'hex'),
      v: Buffer.from(formatHex(v), 'hex'),
      r: Buffer.from(formatHex(r), 'hex'),
      s: Buffer.from(formatHex(s), 'hex'),
    }

    // https://github.com/ethereumjs/ethereumjs-tx/blob/master/src/transaction.ts
    // https://github.com/ethereumjs/ethereumjs-common/blob/master/src/index.ts
    const tx = new Transaction(raw, {
      common: Common.forCustomChain(1, { chainId, networkId: chainId }, 'petersburg'),
    })
    const serializedTx = tx.serialize().toString('hex')

    const response = await new Promise((resolve, reject) => {
      infura.eth.sendSignedTransaction('0x' + serializedTx, (err, re) => {
        if (err) {
          reject('sendSignedTransaction err' + err)
        } else {
          resolve(re)
        }
      })
    })
    console.log('sendSignedTransaction ', response)
    return response
  }

  static async sendBTCTxsend(trezorBtcTx) {
    const res = await TrezorConnect.signTransaction(trezorBtcTx)
    if (!res.success) {
      throw new Error('signTransaction error ' + res.payload.error)
    }
    const res2 = await sendBtcTx(res.payload.serializedTx)
    console.log('broadcastBtcTx', res2)
    return res2.txid
  }
}
