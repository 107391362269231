import React, { useEffect, useState } from 'react'
import TrezorConnect from '@trezor/connect-web'
import * as api from '../../../services/api'
import {
  ZEC_USER_WALLET_BASE_PATH,
  ZEC_MAIN_WALLET_FULL_PATH,
  ZEC_MAIN_WALLET_KEY,
  ZEC_XPUB_KEY,
} from '../../../config'

const WALLET_KEY = ZEC_XPUB_KEY
const BASE_XPUB_PATH = ZEC_USER_WALLET_BASE_PATH

export const ZCashConfig = () => {
  const [walletId, setWalletId] = useState(null)
  const [xpub, setXpub] = useState(localStorage.getItem(WALLET_KEY))
  const [sendingAddr, setSendingAddr] = useState(localStorage.getItem(ZEC_MAIN_WALLET_KEY))
  const [show, setShow] = useState(false)

  useEffect(() => {
    setXpub(localStorage.getItem(WALLET_KEY))
  }, [])

  const getXpub = async () => {
    try {
      const res = await TrezorConnect.getPublicKey({ path: BASE_XPUB_PATH })
      const { xpub } = res.payload
      setXpub(xpub)
      localStorage.setItem(WALLET_KEY, xpub)
    } catch (e) {
      alert(`获取失败 ${e.message}`)
    }
  }

  const clearStorage = () => {
    localStorage.removeItem(WALLET_KEY)
    window.location.reload()
  }

  const saveWallet = async () => {
    try {
      const xpub = localStorage.getItem(WALLET_KEY)
      if (xpub === null) throw new Error('xpub is null')
      const sendingAddr = localStorage.getItem(ZEC_MAIN_WALLET_KEY)
      if (sendingAddr === null) throw new Error('sendingAddr is null')
      const r = await api.setWallet({
        coin: 'ZEC',
        coinType: 'ZEC',
        xpub,
        xpubPath: BASE_XPUB_PATH,
        testnet: false,
        sendingAddr,
      })
      setWalletId(r.id)
      alert('保存成功')
    } catch (e) {
      alert(`保存失败 ${e.message}`)
    }
  }

  const getSendingAddress = async () => {
    try {
      const res = await TrezorConnect.getAddress({ path: ZEC_MAIN_WALLET_FULL_PATH })
      if (!res.success) {
        throw new Error(res.payload.error)
      }
      const { address } = res.payload
      setSendingAddr(address)
      localStorage.setItem(ZEC_MAIN_WALLET_KEY, address)
    } catch (e) {
      alert(`获取失败 ${e.message}`)
    }
  }

  const toggleShow = () => {
    setShow((show) => !show)
  }

  if (!show) {
    return <h2 onClick={toggleShow}>ZCash Wallet (click to show)</h2>
  }

  return (
    <div>
      <h2 onClick={toggleShow}>ZCash Wallet (click to hide)</h2>
      <div className={'bg-slate-200'}>
        <div>
          <p>wallet id: {walletId || 'wallet not saved'}</p>
          <button onClick={clearStorage} className={'btn btn-sm'}>
            clear storage cache
          </button>
          <button onClick={saveWallet} className={'btn btn-sm'}>
            save wallet
          </button>
        </div>
        <div>
          <p>wallet xpub: {xpub}</p>
          <button onClick={getXpub} className={'btn btn-sm'}>
            get xpub key
          </button>
        </div>
        <div>
          <p>wallet sending addr: {sendingAddr}</p>
          <button onClick={getSendingAddress} className={'btn btn-sm'}>
            get sending addr
          </button>
        </div>
      </div>
    </div>
  )
}
